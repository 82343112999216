<template>
<header class="bg-header" :class="{'scrolled-nav' : scrollNav}"> <!--  :class="{'scrolled-nav' : scrollNav}" -->
    <div class="content header">
      <div class="branding">
        <img src="@/assets/logo/logo-juf-financeiro-white.svg" alt="JU Financeiro logo" width="100">
      </div>

      <ItemsList />

    </div>
  </header>
</template>

<script>
import ItemsList from '@/components/menu/ItemsList.vue'

export default {
  name: 'HeaderComponent',
  components: { ItemsList },
  data () {
    return {
      scrollNav: null,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.scrollY
      if(scrollPosition > 50) {
        this.scrollNav = true
        return
      }
      this.scrollNav = false
    },
  }
}
</script>

<style scoped>
  .bg-header {
    position: fixed;
    width: 100%;
    z-index: 900;
    opacity: 1;
  }
  .header {
    padding: 14px;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .scrolled-nav {
    background: linear-gradient(1000deg, rgba(44, 190, 249, 1) -7%, #386DDA 41%, rgba(118, 71, 207, 1) 111.39%);
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .scrolled-nav nav {
    padding: 5px 0;
  }
  .scrolled-nav nav .branding img {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 80px;
  }
  .scrolled-nav .content {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .scrolled-nav .content {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  @media(min-width: 992px) {
    .branding>img {
      width: 150px;
    }
    nav {
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;
    }
    .link {
      font-size: 0.75rem;
    }
  }
  @media (min-width: 1200px) {
    nav {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
</style>