<template>
  <CookieMessage />

  <div class="main-header">
    <HeaderComponent />
  </div>

  <main class="main">
    <router-view />
  </main>

  <div class="main-footer">
    <FooterComponent />
  </div>

  <div class="top-btn">
    <button @click="scrollTop()" title="Ir ao topo da página">
      <img src="@/assets/icons/icon-arrow-top-white-40x40-min.svg" alt="">
    </button>
  </div>
</template>

<script>
import CookieMessage from '@/components/cookies/CookieMessage.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: 'HomeView',
  components: {
    CookieMessage,
    HeaderComponent,
    FooterComponent
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style>
html {
    scroll-behavior: smooth;
  }
.top-btn {
    position: fixed;
    bottom: 30px;
    right: 20px;
  }
  .top-btn button{
    padding: 10px;
    border-radius: 100%;
    opacity: 0.8;
    background: var(--p5);
    border: none;
  }
</style>
